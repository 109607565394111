import React from "react";
import DashBoard from "../dashboard/DashBoard";

const Home = () => {
  return (
    <div>
      <DashBoard />
    </div>
  );
};

export default Home;
